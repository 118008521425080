import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {useState} from "react";
import Posts from "./Posts";
import Stories from "./Stories";

function InstaTab({username}) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    return (
        <Tabs defaultIndex={0} isFitted variant='enclosed' onChange={handleTabChange}>
            <TabList  mb='1em'>
                <Tab>Stories</Tab>
                <Tab>Posts</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Stories username={username}/>
                </TabPanel>
                <TabPanel>
                    { activeTab === 1 &&<Posts username={username}/>}
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default InstaTab