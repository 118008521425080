import React from 'react';
import {
    Box,
    Modal, ModalBody, ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import Media from "./Media";
import ReactGA from "react-ga4";
import { useState, useEffect } from 'react';

function Post({post}) {
    // console.log("Post", post)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [appParam, setAppParam] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const appValue = searchParams.get('app');
        setAppParam(appValue);
        console.log('App param:', appValue);
    }, []);

    function onClick() {
        if (post.media_type !== 2 && appParam !== "iOS") {
            onOpen()
        }
        ReactGA.send({ hitType: "event",
            eventCategory: post.media_type !== 2 ? "Post" : "Story",
            eventAction: "Click",
            eventLabel: post.id
        });
    }

    return (
        <Box onClick={onClick}>
            <Media media={post}/>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody>
                        {
                            post.media_type === 8 ?
                                (
                                    post.carousel_media.map((media) => (
                                        <Media key={media.id} media={media}/>
                                    ))
                                ) :
                                (<Media media={post}/>)
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>

    )
}

export default Post