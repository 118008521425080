import {
    Modal, ModalBody, ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import Media from "./Media";
import InfiniteScroll from "react-infinite-scroller";


function Highlights({highlightId, isOpen, onClose}) {
    const [stories, setStories] = useState([]);
    useEffect(() => {
        if (isOpen) {
            loadMore();
        }
        // eslint-disable-next-line
    }, [isOpen, highlightId]);
    useEffect(() => {
        setStories([])
    }, [onClose])

    function loadMore() {
        const id = highlightId.replace("highlight:", "")
        const url = "https://ext-api.getghostify.com/scraper/v1/highlight_info?highlight_id=" + id
        console.log(url)
        fetch(url, {
            headers: {
                'X-Auth': 'ajsanf233naedfnklbm12223'
            }
        })
            .then(response => response.json())
            .then(data => {
                setStories(data.data.items);
            })
            .catch(error => {
                console.error(error);
            });
    }


    return (<>
            <Modal blockScrollOnMount={false} scrollBehavior={"outside"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Stories</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <InfiniteScroll
                            initialLoad={isOpen}
                            pageStart={0}
                            loadMore={loadMore}
                            hasMore={false}
                            loader={<div className="loader" key={0}>Loading ...</div>}
                        >
                            {
                                stories.map((media) => (
                                    <Media key={media.id} media={media}/>
                                ))
                            }
                        </InfiniteScroll>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Highlights