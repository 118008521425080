import React, { useEffect } from 'react';
import {
    Avatar,
    Box,
    CircularProgress,
    Flex,
    HStack,
    Text,
    VStack
} from "@chakra-ui/react";
import useSWR from "swr";
import Error from "./Error";


function Info({ username, setPrivateState, ...props }) {
    const {
        data,
        error,
        isLoading
    } = useSWR("https://ext-api.getghostify.com/scraper/v1/info?username_or_id_or_url=" + username)

    useEffect(() => {
        if (data && data.data) {
            setPrivateState(data.data.is_private ? 1 : 0);
        }
    }, [data, setPrivateState]);

    function convertThumb(info) {
        const url = encodeURIComponent(info?.profile_pic_url_hd)
        return `https://ext-api.getghostify.com/media?url=${url}`
    }

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num;
        }
    };

    if (isLoading) return <CircularProgress isIndeterminate color='green.300'/>
    if (error) return <Error/>
    if (data.data === undefined) return <Error message={"The username you entered does not exist"}/>

    return (
        <HStack m={6} justify={"center"} spacing={2}>
            <Avatar src={convertThumb(data.data)} size={["lg", "xl", "xl"]} name={data.data.username}/>
            <Box ml={8}>
                <Text fontSize={["2xl", "2xl", "3xl"]} fontWeight="bold" mr={4} mb={4}>@{data.data.username}</Text>
                <Flex justify={"space-between"} align="center">
                    <VStack mr={4}>
                        <Text fontSize={"xl"} fontWeight={"bold"}>{formatNumber(data.data.media_count)}</Text>
                        <Text fontSize={"l"}>posts</Text>
                    </VStack>
                    <VStack mr={4}>
                        <Text fontSize={"xl"} fontWeight={"bold"}>{formatNumber(data.data.follower_count)}</Text>
                        <Text fontSize={"l"}>followers</Text>
                    </VStack>
                    <VStack mr={4}>
                        <Text fontSize={"xl"} fontWeight={"bold"}>{formatNumber(data.data.following_count)}</Text>
                        <Text fontSize={"l"}>following</Text>
                    </VStack>
                </Flex>
            </Box>
        </HStack>
    )
}

export default Info
